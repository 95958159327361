<template>
    <section v-if="blok" v-editable="blok" class="simple-image" :class="{ 'full-width-image': blok.FullWidth }">
        <div class="wrapper" :class="classes" :style="styles">
            <NuxtImg
                v-if="blok.Image?.filename"
                :src="blok.Image?.filename"
                :title="blok.Image.title"
                :alt="blok.Image.alt"
                provider="storyblok"
                format="webp"
                loading="lazy"
                densities="x1"
                class="background-image"
            />
            <div v-if="blok.HoverText" class="bottom">
                <p>{{ blok.HoverText }}</p>
                <i></i>
            </div>
        </div>
    </section>
</template>

<script setup lang="ts">
import type { ISimpleImage } from '~ui/types/components/SimpleImage';

const props = defineProps<{ blok: ISimpleImage }>();

const styles = computed(() => {
    return {
        '--border-color': props.blok.BorderColor?.color || null,
        '--background-color': props.blok.BackgroundColor?.color || null,
        '--width': props.blok.Width ? `${props.blok.Width}px` : `auto`,
        '--height': props.blok.Height ? `${props.blok.Height}px` : `auto`,
        '--image-bg': props.blok.BackgroundImage?.filename ? `url('${props.blok.BackgroundImage?.filename}')` : `none`,
    };
});

const classes = computed(() => {
    return {
        bordered: props.blok.BorderColor?.color || null,
        rounded: props.blok.Rounded || null,
        'image-bg': props.blok.Background || null,
    };
});
</script>

<style lang="postcss" scoped>
.simple-image {
    @apply w-auto relative;

    &.full-width-image {
        @apply w-full;

        :deep(.wrapper),
        :deep(img) {
            @apply w-full;
        }
    }

    &:hover {
        .bottom {
            @apply visible opacity-100;
        }
    }
}

:deep(img) {
    @apply object-contain h-[var(--height)] w-[var(--width)] object-bottom;
}

.wrapper {
    --border-color: #c8d5da;
    background-color: var(--background-color);
    @apply relative;

    &.image-bg {
        @apply my-[12%] z-0;

        &:before {
            @apply absolute h-[150%] w-full bg-contain bg-center bg-no-repeat content-[''] bg-[image:var(--image-bg)] left-2/4 top-2/4;
            transform: translate3d(-50%, -50%, 0);
            background-image: var(--image-bg);
        }

        img {
            @apply relative;
        }
    }

    &.bordered {
        @apply border-[3px] border-[var(--border-color)];
    }

    &.rounded {
        @apply rounded-full;
    }
}

.bottom {
    @apply -translate-x-2/4 font-normal text-[13px] absolute z-[3] box-border shadow-[0_1px_8px_rgba(0,0,0,0.5)] invisible opacity-0 transition-opacity duration-[0.8s] px-3.5 py-0 rounded-lg left-2/4 top-full;

    p {
        @apply text-center;
    }

    .iconify {
        @apply font-normal text-[13px] box-border absolute w-6 h-3 overflow-hidden -ml-3 left-2/4 bottom-full;

        &:after {
            @apply content-[""] absolute w-3 h-3 -translate-x-2/4 translate-y-2/4 rotate-45 bg-white shadow-[0_1px_8px_rgba(0,0,0,0.5)] left-2/4;
        }
    }
}
</style>
